import { Component } from '@angular/core';

@Component({
  selector: 'app-cancel-request',
  templateUrl: './cancel-request.component.html',
  styleUrls: ['./cancel-request.component.scss']
})
export class CancelRequestComponent {
  subject: string = "K12 Refund Request";
  body: string = "Student Name: %0D%0ADate of Birth: %0D%0AReason: ";
}

<div class="container">
    <div class="header mt-4">
        <p>VOLUNTARY K-12 COVERAGE CANCELLATION REQUESTS:</p>
    </div>
    <div class="content">
        <p>In general, voluntary K-12 coverage is non-cancellable and premiums non-refundable.
            Insurance coverage is provided from the time premium is paid until:</p>
        <div class="instList">
            <ul>
                <li>For School-time coverage - the end of the nine-month school term; </li>
                <li>For 24 hour coverage - until the beginning of the following year's school term; or</li>
                <li>For High School Football coverage - until the end of the football season.</li>
            </ul>
        </div>
        <div class="para">
            However,on a case-by case basis there are extenuating circumstances
            that warrant a request for cancellation of coverage.
        </div>
        <div class="footer">
          Parents must submit a written cancellation request to
          <a
            href="mailto:k12request@hsri.com?subject={{subject}}&body={{body}}">k12request&#64;hsri.com</a>
          to include the effective date of cancellation and the reason for cancellation.
          HSR may require documentation to support the cancellation request.
        </div>
    </div>
</div>
